<template>
  <FocusTrap>
    <div class="card">

    <div class="card-header header-elements-inline" >
      <h5 class="card-title">Machine Information</h5>
      <div class="header-elements">
        <div class="list-icons">
          <a class="list-icons-item" data-action="reload"></a>
          <a class="list-icons-item" data-action="remove" @click="closeWindow"></a>
        </div>
      </div>
    </div>

    <div class="card-body">
      <form action="#">

        <div class="form-group row">
          <label class="col-md-2 col-form-label font-weight-semibold">Name:</label>
          <div class="col-md-10">
            <input type="text" class="form-control" placeholder="Enter Machine Name here..." maxlength="100" v-if="machine" v-model="machine.name" >
          </div>
        </div>

        <div class="form-group row">
          <label class="col-md-2 col-form-label font-weight-semibold">Type:</label>
          <div class="col-md-10">
            <select class="form-control" v-if="machine" v-model="machine.type">
              <option value="1">Extrusion</option>
              <option value="2">Warping</option>
              <option value="3">Knitting</option>
              <option value="4">Stentering</option>
            </select>
          </div>
        </div>

<!--        <div class="form-group row">-->
<!--          <label class="col-md-2 col-form-label font-weight-semibold">Print Name:</label>-->
<!--          <div class="col-md-10">-->
<!--            <input type="text" class="form-control" placeholder="Enter print name here..." maxlength="100" v-if="machine" v-model="machine.p_name">-->
<!--          </div>-->
<!--        </div>-->

      </form>
    </div>

    <div class="card-footer">
      <div class="text-right" >
        <button type="button" class="btn btn-primary" @click="saveDocument" >Save<i class="icon-paperplane ml-2"></i></button>
      </div>
    </div>
  </div>
  </FocusTrap>
</template>

<script>
  import FabButton from '@/components/core/FabButton.vue'
  import {userService} from '@/store/auth-header.js'
  export default {
    name: 'MachineForm',
    data () {
      return {
        machine: JSON.parse('{"id":0,"type":0,"name":"","status":"Active"}')
      }
    },
    props: {
      mymachine: {
        type: Object,
        default: () => JSON.parse('{"id":0,"type":0,"name":"","status":"Active"}')
      }
    },
    beforeMount () {
      this.machine = this.mymachine; // save props data to itself's data and deal with tms
    },
    component: {
      FabButton
    },
    created () {
      this.$data.machine = JSON.parse('{"id":0,"type":0,"name":"","status":"Active"}')
    },
    mounted () {

    },
    methods: {
      closeWindow() {
        this.$emit('machine_window_closed');
      },
      saveDocument(){
        const self = this;

        self.$data.machine.type = parseInt(self.$data.machine.type);

        if(self.$data.machine.name.trim().length < 1){
          alert("Invalid Name");
          return
        }else if(self.$data.machine.type < 1){
          alert("Invalid Type");
          return
        }

        self.$data.machine.status = 'Active';

        const requestOptions = {
          method:  (self.$data.machine.id == 0 ? 'POST' : 'PUT'),
          mode:"cors",
          headers: userService.authHeader(),
          body: JSON.stringify(self.$data.machine)
        };


        fetch(`${process.env.VUE_APP_ROOT_API}api/machine`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.ok) {
            swal ( { title: "Success!" ,  text: resp.msg, type:  "success"} );
            self.machine = JSON.parse('{"id":0,"type":0,"name":"","status":"Active"}');
            self.$emit('machine_saved');

          } else {
            swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
        });
      }
    }
  }
</script>

<style scoped>
input:focus {
  background: #feff00;

}

textarea:focus {
  background: #feff00;

}

select:focus {
  background: #feff00;
}

button:focus {
  background: #feff00;
  color: #0a0a0a!important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
