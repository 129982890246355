<template>
  <FocusTrap>
    <div class="card">

      <div class="card-header bg-transparent header-elements-sm-inline py-sm-0">

        <h5 class="card-title py-sm-3"><span class="icon-list"></span>&nbsp;&nbsp;Machine's List</h5>

        <div class="header-elements">
          <form action="#">
            <div class="form-group-feedback form-group-feedback-right">
              <input  id="txtsearch" type="search" class="form-control wmin-sm-200" placeholder="Search..." autocomplete="off">
              <div class="form-control-feedback">
                <i class="icon-search4 font-size-base text-muted"></i>
              </div>
            </div>
          </form>

        </div>
      </div>


      <div class="table-responsive">
        <table id="machine-table"
               class="table  table-hover  table-bordered table-condensed table-columned"
               data-search="false"
               data-pagination="true"
               data-show-refresh="false"
               data-show-columns="false"
               data-page-list="[10, 25, 50, 100, ALL]"
               data-show-footer="false"
               data-toggle="context"
               data-target=".context-table">
          <thead>
          <tr>
            <th data-field="id" data-class="d-none">id</th>
            <th data-field="id" data-width="100">Code</th>
            <th data-field="type" data-formatter="machineTypeFormatter">Type</th>
  <!--          <th data-field="type">Type</th>-->
            <th data-field="name">Name</th>
            <th data-field="status" >Status</th>
          </tr>
          </thead>
          <tbody>
          </tbody>
          <tfoot>
          </tfoot>
        </table>
      </div>

      <!-- Context Menu -->
      <div id="context-menu" class="context-table">
        <div class="dropdown-menu">
          <a href="#" class="dropdown-item" @click="loadData"><i class="icon-reset" style="color: darkgreen"></i>Refresh</a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item"> <i class="icon-pencil3" ></i>Modify</a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item"><i class="icon-blocked text-danger"></i>Remove</a>
        </div>
      </div>
      <!-- End of Context Menu -->

      <!-- Clickable menu -->
      <ul class="fab-menu fab-menu-fixed fab-menu-bottom-right is_stuck" data-fab-toggle="hover" z-index="99">
        <li>
          <a class="fab-menu-btn btn bg-primary-600 btn-float rounded-round btn-icon" @click="showModal">
            <i class="fab-icon-open icon-plus3"></i>
            <i class="fab-icon-close icon-plus3"></i>
          </a>
        </li>
      </ul>
      <!-- /clickable menu -->

      <FocusTrap>
        <vmodal name="machine-window" transition="nice-modal-fade" :delay="100" :resizable="true" width="45%" height="40%" @before-open="beforeOpen" @before-close="beforeClose" >
          <MachineForm v-bind:mymachine="machine" v-on:machine_saved="loadData" v-on:machine_window_closed="closeModal" ></MachineForm>
        </vmodal>
      </FocusTrap>


    </div>
  </FocusTrap>
</template>

<script>
  import MachineForm from '@/components/v1/machine/MachineForm.vue'
  import FabButton from '@/components/core/FabButton.vue'
  import moment from 'moment-timezone'
  import {userService} from '@/store/auth-header.js'
  export default {
    name: 'MachineView',
    components: {
      MachineForm,
      FabButton
    },
    data () {
      return {
        mytable: {},
        machine: JSON.parse('{"id":0,"type":0,"name":"","status":"Active"}')
      }
    },
    created () {

    },
    mounted () {
      const self = this;

      self.$data.machine = JSON.parse('{"id":0,"type":0,"name":"","status":"Active"}');

      this.$data.mytable = $('#machine-table');

      this.$data.mytable.bootstrapTable();
      $('[data-toggle="popover"]').popover();

      this.$data.mytable.contextmenu({
        target: '#context-menu',
        scopes: 'tbody > tr',
        onItem: function (row, e) {
          let id = $(row.children('*')[0]).text();

          if ($(e.target).text() === 'Refresh') {
            self.loadData();
          }else if ($(e.target).text() === 'Modify') {
            self.modifyDocument (id);
          } else if ($(e.target).text() === 'Remove') {
            self.removeDocument(id);
          }
        }
      });

      $('#txtsearch').keyup(function () {
        let val = $.trim($(this).val()).replace(/ +/g, ' ').toLowerCase();

        $('#machine-table>tbody>tr').show().filter(function () {
          let text = $(this).text().replace(/\s+/g, ' ').toLowerCase()
          return !~text.indexOf(val)
        }).hide()
      });

      self.loadData();
    },
    methods: {

      closeModal () {
        this.$modal.hide('machine-window');
      },
      showModal () {
        this.$modal.show('machine-window');
      },
      beforeOpen(){
        console.log('beforeOpen');
      },
      beforeClose(){
        this.$data.machine = JSON.parse('{"id":0,"type":0,"name":"","status":"Active"}');
      },
      loadData () {
        const self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        this.$modal.hide('machine-window');

        self.$data.mytable.bootstrapTable('load',[]);


        fetch(`${process.env.VUE_APP_ROOT_API}api/machines/after/1`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.ok) {
            if(_.isArray(resp.data)){
              self.$data.mytable.bootstrapTable('load', resp.data);
            }
          } else {
            swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
        });
      },
      modifyDocument (id) {
        const self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        self.$data.machine = JSON.parse('{"id":0,"type":0,"name":"","status":"Active"}');

        fetch(`${process.env.VUE_APP_ROOT_API}api/machine/${id}`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.ok) {
            self.$data.machine = resp.data;
            self.$modal.show('machine-window');
          } else {
            swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text:  err.toString() , type:  "error"} );
        });
      },
      removeDocument (id) {
        const self = this;

        self.$data.machine.id = id;

        const requestOptions = {
          method: 'DELETE',
          mode:'cors',
          headers: userService.authHeader(),
          body: JSON.stringify(self.$data.machine)
        };

        swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          type: 'warning',
          timer: 3000,
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete this!'
        }).then((result) => {
          if (result.value) {
            fetch(`${process.env.VUE_APP_ROOT_API}api/machine`,requestOptions).then(userService.handleResponse).then(function (resp) {
              self.$modal.hide('machine-window');
              self.loadData();
              swal('Deleted!',  'Your request has been processed', 'success' );
            }).catch(function (err) {
              swal({ title: "Oops", text: err.toString(), type: "error" });
            });
          }
        });
      }
    }
  }
</script>

<style scoped>
  .modal {
    text-align: center;
    padding: 0!important;
  }

  .modal:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  .modal-dialog {
    display: inline-block;
    text-align: left;
    width: 40%;
    vertical-align: middle;
  }


   input:focus {
     background: #feff00;

   }

  textarea:focus {
    background: #feff00;

  }

  select:focus {
    background: #feff00;
  }

  button:focus {
    background: #feff00;
    color: #0a0a0a!important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

</style>
